<template lang="pug">
  .delivery-single(v-if="article")
    .page__wr
      .delivery-single__content
        h1.delivery-single__title
          | {{h1}}
        .delivery-single__article-wr
          .delivery-single__article-left
            .delivery-single__article-title
              | {{article.title}}
            article.delivery-single__article(v-html="article.txt")
          .delivery-single__article-right
            .delivery-single__article-img-wr
              .delivery-single__article-img-bg(v-show='imgHide')
              img.delivery-single__article-img(:src="article.img", :alt="article.title")

    .delivery-single__calc(v-if="calc")
      .page__wr
        .delivery-single__calc-content
          .delivery-single__calc-title
            | {{calc.title}}
          form.delivery-single__calc-form(v-on:submit.prevent="submit")
            .delivery-single__calc-form-row-input
              input.delivery-single__calc-form-input(v-model="name" type="text" name="name", :placeholder="calc.inputs.name.placeholder" autocomplete="off" required)
              input.delivery-single__calc-form-input(v-model="phone" v-mask="'+7(###)-###-##-##'" type="text" name="phone", :placeholder="calc.inputs.phone.placeholder" autocomplete="off" required)
              .delivery-single__form-row-txt
                | * Обязательные поля для заполнения

            .delivery-single__calc-form-center
              .delivery-single__address-input-wr
                SvgIcon(class='delivery-single__address-input-ico' name="search" width="21" height="22")
                input.delivery-single__address-input(v-model="address" type="text" name="address", :placeholder="calc.inputs.address.placeholder" autocomplete="off")

              .delivery-single__type(v-if="calc.fuelType")
                .delivery-single__type-left
                  | {{calc.fuelType.title}}
                .delivery-single__type-right
                  .delivery-single__type-right-left
                    | {{calc.fuelType.types[0].title}}
                  .delivery-single__type-right-center
                    .delivery-single__type-checkbox-wr
                      input.delivery-single__type-checkbox(v-model="fuelType" type="checkbox" name="fuelType")
                      .delivery-single__type-checkbox-inside-wr
                        .delivery-single__type-checkbox-circle

                  .delivery-single__type-right-right
                    | {{calc.fuelType.types[1].title}}

              .delivery-single__product.js-single-product(v-if="calc.products")
                input.js-single-product-input(type="hidden" name="product")
                .delivery-single__product-top-arrow-wr
                  SvgIcon(class='delivery-single__product-top-ico' name="arrow-left" width="12" height="21")
                .delivery-single__product-top
                  | {{calc.products.title}}

                .delivery-single__product-bottom(v-if="calc.products.items")
                  .delivery-single__product-items
                    .delivery-single__product-item(v-for="itemProducts in calc.products.items", :key="itemProducts.id", :data-value="itemProducts.value")
                      | {{itemProducts.title}}

            .delivery-single__radio-types(v-if="calc.radioTypes")
              .delivery-single__radio-type(v-for="itemRadio in calc.radioTypes", :key="itemRadio.id")
                input.delivery-single__radio-input(v-model="deliveryType" type="radio" name="deliveryType", :value="itemRadio.value")
                .delivery-single__radio-img-wr
                  SvgIcon(class='delivery-single__radio-img-ico', :name="itemRadio.img.name", :width="`${itemRadio.img.width}`", :height="`${itemRadio.img.height}`")
                .delivery-single__radio-title-wr
                  .delivery-single__radio-circle-wr
                    .delivery-single__radio-circle-inner
                  .delivery-single__radio-title
                    | {{itemRadio.title}}

            .delivery-single__send-btn-wr
              button.delivery-single__send-btn(type="submit")
                | Отправить заявку
              .delivery-single__agreement
                p.delivery-single__agreement-text Нажимая кнопку «Отправить», Вы даёте согласие на обработку
                  router-link(to="/politika-konfidencial-nosti" target="_blank")  персональных данных

    .delivery-single__form-feedback
      FormFeedback
    .delivery-single__footer
      Footer
</template>

<script>

import EventBus from '@/eventBus';

import '@/components/deliverySingle/deliverySingle.styl';
import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';

// @ is an alias to /src
import FormFeedback from '@/components/common/formFeedback/formFeedback.vue';
import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
import Footer from '@/components/common/footer/Footer.vue';

export default {
  name: 'DeliverySingle',

  mixins: [AjaxHelpersVue, PopupHelpersVue],

  data() {
    return {
      article: null,
      calc: null,
      name: '',
      phone: '',
      address: '',
      fuelType: '',
      deliveryType: '',
      imgHide: true,
      objImagesArticle: [],
      objImagesCalc: [],
      imgCalc: "@/assets/img/delivery-single-calc-bg.jpg",
    }
  },

  components: {
    FormFeedback,
    SvgIcon,
    Footer
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadDeliverySinglePage', params => {
      this.request(params.url);
    });

    EventBus.$on('imgLoaded', res => {
      this.imgHide = res;
      this.objImagesArticle = [];
      $('.delivery-single__calc').css('background-image', 'url("/img/content/delivery-single-calc-bg.jpg")');
    });
  },

  methods: {
    request(url) {
      this.req('DeliverySinglePage:getDeliverySinglePage', {url}, res => {
        if(this.checkProperty(res.content.article)) {
          this.article = res.content.article;
          this.objImagesArticle = this.objImagesArticle.concat({loaded: false, src: this.article.img});
          this.checkImgLoading(this.objImagesArticle);
          this.objImagesCalc = this.objImagesCalc.concat({loaded: false, src: this.imgCalc});
          this.checkImgLoading(this.objImagesCalc);
        }

        if(this.checkProperty(res.content.calc)) {
          this.calc = res.content.calc;
          this.$nextTick(() => {
            this.initSingleProduct();
          });
        }
      }, 'deliverySingle');
    },

    submit() {
      let $product = $('.js-single-product-input');

      let data = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        fuelType: this.fuelType ? 'темный' : 'светлый',
        deliveryType: this.deliveryType,
        product: $product.val() || ''
      };

      this.req('DeliverySinglePage:sendCalcForm', data, res => {

        if(this.checkProperty(res.status)) {

          if(res.status === 'ok') {
            this.popupLocal('popupMessage', 'show');

            EventBus.$emit('popupMessageDataLoaded', res);

            this.name   = '';
            this.phone  = '';
            this.address   = '';
            this.fuelType = '';
            this.deliveryType = '';
            $product.val('');
          }
        }
      }, null, 'form');
    },

    popupLocal(type, action, res) {
      this.popup(type, action, res);
    },

    initSingleProduct() {
      let $productBlock = $('.js-single-product');

      if($(window).width() < 1200 ) {
        $productBlock.on('click', function() {
          if(!$(this).hasClass('delivery-single__product--open')) {
            $(this).addClass('delivery-single__product--open');
          }
          else {
            $(this).removeClass('delivery-single__product--open');
          }
        })
      }
      else {
        $productBlock.hover(function() {
          $(this).addClass('delivery-single__product--open');
        },
        function() {
          $(this).removeClass('delivery-single__product--open');
        });
      }

      $('.delivery-single__product-item').on('click', function(e) {
        e.stopPropagation();

        let txt = $(this).text();
        let value = $(this).attr('data-value') || '';

        $productBlock.removeClass('delivery-single__product--open');

        $('.delivery-single__product-top').text(txt);
        $('.js-single-product-input').val(value);
      });
    }
  }
}
</script>
