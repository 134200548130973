<template lang="pug">
  #app.page
    .page__burger(v-on:click="showHideMenu")
      svg.ham.hamRotate.ham1(viewBox='0 0 100 100', width='80', :class="{'active': showHide}")
        path.line.top(d='m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40')
        path.line.middle(d='m 30,50 h 40')
        path.line.bottom(d='m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40')

    .page__header.page__header--main
      .page__header-preloader
        Preloader
      .page__wr
        .page__header-wr
          Header
    router-view

    popupCallPhone
    popupOrder
    popupMessage
    MenuMobile
</template>

<script>
  import Header from '@/components/common/header/Header';
  import popupCallPhone from '@/components/common/popups/popupCallPhone/popupCallPhone.vue';
  import popupOrder from '@/components/common/popups/popupOrder/popupOrder.vue';
  import popupMessage from '@/components/common/popups/popupMessage/popupMessage.vue';
  import Preloader from '@/components/common/preloader/Preloader.vue';
  import MenuMobile from '@/components/common/menuMobile/MenuMobile.vue';

  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
  import EventBus from '@/eventBus';

  export default {
    mixins: [PopupHelpersVue],

    data() {
      return {
        showHide: false
      }
    },

    components: {
      Header,
      popupCallPhone,
      popupOrder,
      popupMessage,
      Preloader,
      MenuMobile
    },

    created() {
      EventBus.$on('showHideMenu', showHide => {
        if(!showHide) {
          this.showHide = false;
        }
        else {
          this.showHide = true;
        }
      });
    },

    mounted() {
      this.setCommonCompensateScrollbarClasses();
    },

    methods: {
      setCommonCompensateScrollbarClasses() {
        let scrollbarWidth = this.measureScrollbar();

        if(!$('#compensate-common').length) {
          $('head')
            .append(`<style id="compensate-common">
                      .page:not([data-block="main"]) .page__header{width: calc(100% + ${scrollbarWidth}px) !important;}
                      </style>`);
        }

        // Для анимации страницы Продукция
        // .page:not([data-block="main"]):not([data-block="production"]) .page__header{width: calc(100% + ${scrollbarWidth}px);}
      },

      showHideMenu() {
        EventBus.$emit('showHideMenu', this.showHide = !this.showHide);
      }
    }
  }
</script>
