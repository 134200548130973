import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
import EventBus from '@/eventBus';

export const FormHelpersVue = {
  mixins: [AjaxHelpersVue, PopupHelpersVue],

  methods: {
    submitForm(api, data, callback) {

      if($.isFunction(callback)) {
        callback();
      }

      /*setTimeout(() => {
        this.popup('popupMessage', 'show');

        //console.log('show');


        /!*setTimeout(() => {
          console.log('close');

          this.popup('popupMessage', 'close');
        }, 2000);*!/
      }, 500);*/

      this.req(api, data, res => {

        console.log(res);

        if(this.checkProperty(res.status)) {

          if(res.status === 'ok') {
            if($.isFunction(callback)) {
              callback(res);
            }

            // Если нужно отправить данные в попап, например, сообщение
            EventBus.$emit('popupMessageDataLoaded', res);

            setTimeout(() => {
              this.popup('popupMessage', 'show');
            }, 500);
          }
        }
      }, null, 'form');
    }
  }
};
