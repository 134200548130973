<template lang="pug">
  .production(v-if="btns")
    .production__content
      .production__bg-wr
        .production__bg-bottom
        .production__bg-top

      .production__bg-lines-wr
        .production__bg-lines
          Particles(color="#8d5100", :particleOpacity="1", :particlesNumber="80" shapeType="circle", :particleSize="4" linesColor="#8d5100", :linesWidth="2", :lineLinked="true", :lineOpacity="1", :linesDistance="150", :moveSpeed="3", :hoverEffect="true" hoverMode="grab", :clickEffect="true" clickMode="push", id="js-particles-production" )
      .page__wr
        .production__content-wr
          .production__title
            | {{h1}}
          .production__btns-wr
            router-link.production__content-btn(v-for="btn in btns", :key="btn.title", :to="btn.link")
              | {{btn.title}}
    .production__footer
      Footer
</template>

<script>
import EventBus from '@/eventBus';

import '@/components/production/production.styl';
import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

// @ is an alias to /src
import Particles from '@/components/common/vue-particles/vue-particles.vue';
import Footer from '@/components/common/footer/Footer.vue';

export default {
  name: 'Main',

  mixins: [AjaxHelpersVue],

  data() {
    return {
      btns: null,
      showParticles: false
    }
  },

  components: {
    Particles,
    Footer
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadProductionPage', params => {
      this.request(params.url);
    });

    EventBus.$on('changePage', blockId => {
      this.showParticles = blockId !== 'main' ? true : false;
    });
  },

  methods: {
    request(url) {
      this.req('ProductionPage:getProductionPage', {url}, res => {
        if(this.checkProperty(res.content.btns)) {
          this.btns = res.content.btns;

          this.$nextTick(() => {
            //this.parallaxInit();
          });
        }
      }, 'production');
    },

    parallaxInit() {
      // let $bgLines = $('.production__bg-lines');
      let $bgBottom = $('.production__bg-bottom');
      // let $bgTop = $('.production__bg-top');

      $(document).on('mousemove', function(e) {
        let offsetX = e.clientX;
        let offsetY = e.clientY;

        $bgBottom.css('transform', `translate3d(${offsetX * 0.005}px, ${offsetY * 0.005}px, 0) scale(1.05)`);
        // $bgTop.css('transform', `translate3d(-${offsetX * 0.0055}px, -${offsetY * 0.0055}px, 0) scale(1.05)`);
        // $bgLines.css('transform', `translate3d(${offsetX * 0.009}px, ${offsetY * 0.009}px, 0) `);
      });
    }
  }
}
</script>
