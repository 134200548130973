<template lang="pug">
    .policy
        h2.policy__headline Политика обработки персональных данных
        .policy__content
            .page__wr
                .policy__content-block(v-html="content")
        .policy__footer
            Footer
</template>

<script>
    import EventBus from '@/eventBus';
    import '@/components/privacyPolicy/privacyPolicy.styl';
    import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

    import Footer from '@/components/common/footer/Footer.vue';

    export default {
        name: 'PrivacyPolicy',
        mixins: [AjaxHelpersVue],
        data() {
            return {
                content: null
            }
        },
        components: {
            Footer
        },
        created() {
            EventBus.$on('loadPrivacyPolicy', params => {
                this.request(params.url);
            });
        },
        methods: {
            request(url) {
                this.req('PolicyPage:getPolicyPage', {url}, res => {
                    if(this.checkProperty(res.content)) {
                        this.content = res.content;
                    }
                }, 'privacyPolicy');
            },
        }
    }
</script>
