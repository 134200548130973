//import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
//import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
//import EventBus from '@/eventBus';

export const AnimaHelpersVue = {
  methods: {
    initParallax($block) {
      $(document).on('mousemove', function(e) {
        let offsetX = e.clientX;
        let offsetY = e.clientY;

        $block.css('transform', `translate3d(${offsetX * 0.0075}px, ${offsetY * 0.0075}px, 0) `);
      });
    }
  }
};
