<template lang="pug">
  .about(v-if="content")
    .page__wr
      .about__content
        h1.about__title
          | {{h1}}
        .about__content-desc
          .about__content-desc-left
            .about__content-desc-title
              | {{content.title}}
            article.about__content-desc-article(v-html="content.article")
          .about__content-desc-right
            .about__content-slider.js-slider-about-content(v-if="content.slider")
              .swiper-container
                .swiper-wrapper
                  .swiper-slide(v-for="item in content.slider", :key="item.id")
                    .about__content-slider-item
                      img.about__content-slider-item-img(:src='item.img', :alt="content.title")
              .about__content-slider-pagination
                .swiper-pagination

    .about__form-feedback
      FormFeedback(:dataForm="dataForm")
    .about__footer
      Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/about/about.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from '@/mixins/SliderHelpersVue';

  // @ is an alias to /src
  import FormFeedback from '@/components/common/formFeedback/formFeedback.vue';
  import Footer from '@/components/common/footer/Footer.vue';

  export default {
    name: 'About',

    mixins: [AjaxHelpersVue, SliderHelpersVue],

    data() {
      return {
        content: null,
        dataForm: null
      }
    },

    components: {
      FormFeedback,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadAboutPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('AboutPage:getAboutPage', {url}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;
            this.dataForm = res.content.form;

            this.$nextTick(() => {
              this.initSlider('.js-slider-about-content', {
                slidesPerView: 1
              });
            });
          }
        }, 'about');
      }
    }

  }
</script>
