<template lang="pug">
  .page-404(v-if="content")
    .page__wr
      .page-404__content
        .page-404__left
          h1.page-404__title(v-html="h1")
          .page-404__subtitle(v-html="content.subtitle")
          router-link.page-404__back-btn(:to="content.backLnk.href")
            | {{content.backLnk.title}}
        .page-404__right
          .page-404__right-bg(:style="`background-image: url('${content.rightBg}');`")

    .page-404__footer
      Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/page404/page404.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  // @ is an alias to /src
  import Footer from '@/components/common/footer/Footer.vue';

  export default {
    name: 'About',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('load404Page', () => {
        this.request();
      });
    },

    methods: {
      request() {
        // Нейминг должен быть такой, без цифр в начале
        this.req('NotFoundPage:getNotFoundPage', {url: '*'}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;
          }
        }, 'page-404');
      }
    }

  }
</script>
