<template lang="pug">
  .amazing-spinner
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
    .amazing-spinner__item
</template>

<script>
  import '@/components/common/amazingSpinner/amazingSpinner.styl';

  export default {
    name: 'amazingSpinner',

    data() {
      return {}
    },

    components: {}

  }
</script>
