<template lang="pug">
  .form-feedback(v-if="dataForm")
    .form-feedback__bg
      video.form-feedback__bg-video(src='/video/oil-new.mp4' autoplay preload='auto' muted loop playsinline)
    .form-feedback__content
      .form-feedback__title
        | {{dataForm.title}}
      .form-feedback__subtitle
        | {{dataForm.subtitle}}
      form.form-feedback__form(v-on:submit.prevent="submit")
        .form-feedback__row-wr
          .form-feedback__row
            input.form-feedback__input(v-model="name" type="text" name="name", :placeholder="dataForm.inputName.placeholder" autocomplete="off" required)
            input.form-feedback__input(v-model="phone", :v-mask="dataForm.inputPhone.mask" type="text" name="phone", :placeholder="dataForm.inputPhone.placeholder" autocomplete="off" required)
          .form-feedback__row-input-txt
            | {{dataForm.requiredTxt}}
        .form-feedback__submit-btn-wr
          button.form-feedback__submit-btn(type="submit")
            | {{dataForm.submitBtn}}
          .form-feedback__agreement
            p.form-feedback__agreement-text(v-html="dataForm.agreement")
</template>

<script>
  import '@/components/common/formFeedback/formFeedback.styl';
  import {FormHelpersVue} from '@/mixins/FormHelpersVue';

  export default {
    name: 'FormFeedback',

    mixins: [FormHelpersVue],

    props: {
      dataForm: Object,
      default: null
    },

    data() {
      return {
        name: '',
        phone: ''
      }
    },

    methods: {
      submit() {
        let data = {
          name: this.name,
          phone: this.phone
        };

        this.submitForm('FormFeedback:sendFormFeedback', data, res => {
          this.name = '';
          this.phone = '';
        });
      }
    }
  }
</script>
