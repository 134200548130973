<template lang="pug">
  .catalog(v-if="items")
    .page__wr
      .catalog__content
        h1.catalog__title
          | {{h1}}
        .catalog__items
          router-link.catalog__item(v-for="item in items", :to="item.lnk", :key="item.id")
            .catalog__item-img-wr
              img.catalog__item-img(:src="item.img", :alt="item.title")
              CoolHover
            .catalog__item-title
              | {{item.title}}
    .catalog__form
      FormFeedback(:dataForm="dataForm")
    .catalog__footer
      Footer
</template>

<script>

import EventBus from '@/eventBus';

import '@/components/catalog/catalog.styl';
import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

// @ is an alias to /src
import CoolHover from '@/components/common/coolHover/CoolHover.vue';
import FormFeedback from '@/components/common/formFeedback/formFeedback.vue';
import Footer from '@/components/common/footer/Footer.vue';

export default {
  name: 'Main',

  mixins: [AjaxHelpersVue],

  data() {
    return {
      items: null,
      dataForm: null
    }
  },

  components: {
    CoolHover,
    FormFeedback,
    Footer
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadCatalogPage', params => {
      this.request(params.url);
    });
  },

  methods: {
    request(url) {
      this.req('CatalogPage:getCatalogPage', {url}, res => {
        if(this.checkProperty(res.content.items)) {
          this.items = res.content.items;
          this.dataForm = res.content.form;
        }
      }, 'catalog');
    }
  }

}
</script>
