<template lang="pug">
  .slider-similar
    .slider-similar__title
      | {{productSimilar.title}}
    .slider-similar__slider.js-slider-similar
      .swiper-container
        .swiper-wrapper
          .swiper-slide(v-for="item in productSimilar.items", :key="item.id")
            router-link.slider-similar__item(:to="item.lnk")
              .slider-similar__item-img-wr
                img.slider-similar__item-img(:src="item.img", :alt="item.title")
                .slider-similar__item-img-layout
                CoolHover
              .slider-similar__item-title
                | {{item.title}}

      .swiper-button-prev
        SvgIcon(class='slider-similar__arrow-ico' name="arrow-left" width="23" height="31")
      .swiper-button-next
        SvgIcon(class='slider-similar__arrow-ico' name="arrow-left" width="23" height="31")
</template>

<script>
  import '@/components/common/sliderSimilarProducts/sliderSimilarProducts.styl';
  import {SliderHelpersVue} from '@/mixins/SliderHelpersVue';

  // @ is an alias to /src
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import CoolHover from '@/components/common/coolHover/CoolHover.vue';

  export default {
    name: 'sliderSimilarProducts',

    mixins: [SliderHelpersVue],

    props: {
      productSimilar: {
        type: Object,
        required: true
      }
    },

    data() {
      return {}
    },

    components: {
      SvgIcon,
      CoolHover
    },

    mounted() {
      this.initSlider('.js-slider-similar', {
        breakpointsInverse: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        }
      });
    },

    methods: {

    }

  }
</script>


