<template lang="pug">
  .popup-order.js-popup-order(v-if="content")
    .popup-order__layout(v-on:click="popupLocal('popupOrder', 'close')")
    .popup-order__content-wr
      .popup-order__content-layout
        .popup-order__lines
          Particles( color="#fedb8b", :particleOpacity="1", :particlesNumber="80" shapeType="circle", :particleSize="3" linesColor="#fedb8b", :linesWidth="1", :lineLinked="true", :lineOpacity="1", :linesDistance="150", :moveSpeed="1", :hoverEffect="true" hoverMode="grab", :clickEffect="true" clickMode="push", id="js-particles-popup-order")
      .popup-order__close-wr(v-on:click="popupLocal('popupOrder', 'close')")
        SvgIcon(class='popup-order__close-ico' name="close" width="42" height="42")

      .popup-order__content
        .popup-order__title-wr
          .popup-order__title
            | {{content.title}}
          .popup-order__close-wr.popup-order__close-wr--mobile(v-on:click="popupLocal('popupOrder', 'close')")
            SvgIcon(class='popup-order__close-ico' name="close" width="42" height="42")
        form.popup-order__form(v-on:submit.prevent="submit")
          .popup-order__input-wr(v-if="content.inputName")
            input.popup-order__input(v-model="name" type="text" name="name", :placeholder="content.inputName.placeholder" autocomplete="off" required)
          .popup-order__input-wr(v-if="content.inputPhone")
            input.popup-order__input(v-model="phone", v-mask="content.inputPhone.mask" type="phone" name="phone", :placeholder="content.inputPhone.placeholder" autocomplete="off" required)
            .popup-order__input-txt(v-html="content.requiredTxt")

          .popup-order__spec.js-popup-order-spec(v-if="spec")
            .popup-order__spec-top-arrow-wr
              SvgIcon(class='popup-order__spec-top-ico' name="arrow-left" width="12" height="21")
            .popup-order__spec-top
              | {{spec.title}}
            .popup-order__spec-bottom
              VuePerfectScrollbar(class="scroll-area" v-once :settings="settings")
                .popup-order__spec-items
                  .popup-order__spec-item(v-for="item in spec.items", :key="item.id", :data-value="item.value", v-on:click="setSpecValue(item.value)")
                    | {{item.title}}

          textarea.popup-order__textarea(v-model="comment" name="comment", :placeholder="content.textAreaComment.placeholder")
          .popup-order__btn-wr
            button.popup-order__btn(type="submit")
              | {{content.btnSubmit.title}}
            .popup-order__agreement
              p.popup-order__agreement-text(v-html="content.agreement")

</template>

<script>
  import EventBus from '@/eventBus';

  import Particles from '@/components/common/vue-particles/vue-particles.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';

  // @ is an alias to /src
  import '@/components/common/popups/popupOrder/popupOrder.styl';

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
  import {FormHelpersVue} from '@/mixins/FormHelpersVue';
  import {AnimaHelpersVue} from '@/mixins/AnimaHelpersVue';

  export default {
    name: 'PopupCall',

    mixins: [AjaxHelpersVue, PopupHelpersVue, FormHelpersVue, AnimaHelpersVue],

    components: {
      Particles,
      SvgIcon,
      VuePerfectScrollbar
    },

    data() {
      return {
        content: null,
        spec: null,
        name: '',
        phone: '',
        comment: '',
        specValue: '',
        settings: {
          maxScrollbarLength: 60
        }

      }
    },

    created() {
      EventBus.$on('loadFormOrder', url => {
        this.request(url);
      });
    },

    methods: {

      request(url) {
        this.req('FormVacancy:getFormVacancy', {url}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;

            this.$nextTick(() => {
              this.popupLocal('popupOrder', 'show');

              this.initParallax($('.popup-order__lines'));
            });
          }

          if(this.checkProperty(res.spec)) {
            this.spec = res.spec;

            this.$nextTick(() => {
              this.initSpecBlock();
            });
          }
        }, null, 'form');
      },

      popupLocal(type, action) {
        this.popup(type, action);
      },

      submit() {
        let data = {
          name: this.name,
          phone: this.phone,
          comment: this.comment,
          spec: this.specValue
        };

        this.submitForm('FormVacancy:sendFormVacancy', data, () => {
          this.popupLocal('popupOrder', 'close');

          this.name  = '';
          this.phone = '';
          this.comment = '';
          this.specValue = ''
        });
      },

      setSpecValue(value) {
        this.specValue = value;
      },

      initSpecBlock() {
        let $specBlock = $('.js-popup-order-spec');

        if($(window).width() < 1200) {
          $specBlock.on('click', function() {
            if(!$(this).hasClass('popup-order__spec--open')) {
              $(this).addClass('popup-order__spec--open');
            }
            else {
              $(this).removeClass('popup-order__spec--open');
            }
          })
        }
        else {
          $specBlock.hover(function() {
            $(this).addClass('popup-order__spec--open');
          },
          function() {
            $(this).removeClass('popup-order__spec--open');
          });
        }

        $('.popup-order__spec-item').on('click', function(e) {
          e.stopPropagation();

          let txt = $(this).text();

          $specBlock.removeClass('popup-order__spec--open');

          $('.popup-order__spec-top').text(txt);
        });
      }
    }
  }
</script>
