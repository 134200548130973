<template lang="pug">
  .page__items
    .page__item(data-id="main")
      Main
    .page__item(data-id="production")
      Production
    .page__item(data-id="catalog")
      Catalog
    .page__item(data-id="catalogSingle")
      CatalogSingle
    .page__item(data-id="delivery")
      Delivery
    .page__item(data-id="deliverySingle")
      DeliverySingle
    .page__item(data-id="about")
      About
    .page__item(data-id="contacts")
      Contacts
    .page__item(data-id="page-404")
      Page404
    .page__item(data-id="privacyPolicy")
      PrivacyPolicy
</template>

<script>
  import EventBus from '@/eventBus';

  import Main from '@/views/Main';
  import Production from '@/views/Production';
  import Catalog from '@/views/Catalog';
  import CatalogSingle from '@/views/CatalogSingle';
  import Delivery from '@/views/Delivery';
  import DeliverySingle from '@/views/DeliverySingle';
  import About from '@/views/About';
  import Contacts from '@/views/Contacts';
  import Page404 from '@/views/Page404';
  import PrivacyPolicy from '@/views/PrivacyPolicy';

  export default {
    components: {
      Main,
      Production,
      Catalog,
      CatalogSingle,
      Delivery,
      DeliverySingle,
      About,
      Contacts,
      Page404,
      PrivacyPolicy
    },

    /*beforeEach(to, from, next) {
      console.log(to);


      next();
    },*/

    // Первый заход на страницу & для роутов типа /catalog/:id
    beforeRouteEnter(to, from, next) {
      next(vm => {
        // экземпляр компонента доступен как `vm`

        let params = vm.getParams(to);

        EventBus.$emit('setMenuTopActiveClass', to.path);
        EventBus.$emit(params.event, params);
      })
    },

    // Навигация по ссылкам
    beforeRouteUpdate(to, from, next) {
      let params = this.getParams(to);

      EventBus.$emit('setMenuTopActiveClass', to.path);
      EventBus.$emit(params.event, params);

      next();
    },

    methods: {
      getParams(route) {
        let path  = route.path;
        let url = route.path || '';

        let routeName = route.name || '';

        let event = '';
        let params = {};

        //console.log(route);

        if(path === '/') {
          event = 'loadMainPage';
        }
        else if(path === '/produkciya') {
          event = 'loadProductionPage';
        }
        else if(path === '/dostavka') {
          event = 'loadDeliveryPage';
        }
        else if(path === '/o-kompanii') {
          event = 'loadAboutPage';
        }
        else if(path === '/kontakty') {
          event = 'loadContactsPage';
        }
        else if(path === '/politika-konfidencial-nosti') {
          event = 'loadPrivacyPolicy';
        }
        else {
          event = 'load404Page';
        }

        //console.log(routeName);
        //console.log(route);

        if(routeName === 'Catalog') {
          event = 'loadCatalogPage';
          params.type = route.params.type || '';
        }

        if(routeName === 'CatalogSingle') {
          event = 'loadCatalogSinglePage';
          params.product = route.params.type || '';
        }

        if(routeName === 'DeliverySingle') {
          event = 'loadDeliverySinglePage';
          params.type = route.params.type || '';
        }

        //console.log(event);

        params.event = event || '';
        params.url = url;

        //console.log(params);

        return params;
      }
    }
  }
</script>
