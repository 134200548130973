import Vue from 'vue';
import Router from 'vue-router';
import Index from './components/index/Index';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/produkciya/:type',
      name: 'Catalog',
      component: Index,
      children: [
        {
          path: ':product',
          name: 'CatalogSingle',
          component: Index
        }
      ]
    },

    {
      path: '/dostavka/:type',
      name: 'DeliverySingle',
      component: Index
    },

    {
      path: '/politika-konfidencial-nosti',
      name: 'PrivacyPolicy',
      component: Index
    },

    {
      path: '*',
      name: 'Index',
      component: Index
    }
    /*{
      path: '/o-nas',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue')
    }*/
  ]
})
