<template lang="pug">
  .delivery(v-if="items")
    .page__wr
      .delivery__content
        h1.delivery__title
          | {{h1}}
        .delivery__items
          // 1 col
          .delivery__item
            router-link.delivery__item-el(:to="items[0].lnk" )
              CoolHover
              .delivery__item-el-bg(:style="`background-image: url(${items[0].img});`")
              .delivery__item-el-layout
              .delivery__item-el-info
                SvgIcon(class='delivery__item-el-ico' name="tank-truck" width="95" height="63")
                .delivery__item-el-title
                  | {{items[0].title}}
            router-link.delivery__item-el(:to="items[1].lnk")
              CoolHover
              .delivery__item-el-bg(:style="`background-image: url(${items[1].img});`")
              .delivery__item-el-layout
              .delivery__item-el-info
                SvgIcon(class='delivery__item-el-ico' name="tank-truck-1" width="75" height="67")
                .delivery__item-el-title
                  | {{items[1].title}}

          // 2 col
          .delivery__item
            router-link.delivery__item-el(:to="items[2].lnk")
              CoolHover
              .delivery__item-el-bg(:style="`background-image: url(${items[2].img});`")
              .delivery__item-el-layout
              .delivery__item-el-info
                SvgIcon(class='delivery__item-el-ico' name="valve" width="79" height="70")
                .delivery__item-el-title
                  | {{items[2].title}}
            router-link.delivery__item-el(:to="items[3].lnk")
              CoolHover
              .delivery__item-el-bg(:style="`background-image: url(${items[3].img});`")
              .delivery__item-el-layout
              .delivery__item-el-info
                SvgIcon(class='delivery__item-el-ico' name="cont" width="69" height="37")
                .delivery__item-el-title
                  | {{items[3].title}}

          // 3 col
          .delivery__item
            router-link.delivery__item-el(:to="items[4].lnk")
              CoolHover
              .delivery__item-el-bg(:style="`background-image: url(${items[4].img});`")
              .delivery__item-el-layout
              .delivery__item-el-info
                SvgIcon(class='delivery__item-el-ico' name="cargo-ship" width="87" height="52")
                .delivery__item-el-title
                  | {{items[4].title}}

    .delivery__footer
      Footer
</template>

<script>

import EventBus from '@/eventBus';

import '@/components/delivery/delivery.styl';
import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

// @ is an alias to /src
import CoolHover from '@/components/common/coolHover/CoolHover.vue';
import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
import Footer from '@/components/common/footer/Footer.vue';

export default {
  name: 'Delivery',

  mixins: [AjaxHelpersVue],

  data() {
    return {
      items: null
    }
  },

  components: {
    CoolHover,
    SvgIcon,
    Footer
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadDeliveryPage', params => {
      this.request(params.url);
    });
  },

  methods: {
    request(url) {
      this.req('DeliveryPage:getDeliveryPage', {url}, res => {
        if(this.checkProperty(res.content.items)) {
          this.items = res.content.items;
        }
      }, 'delivery');
    }
  }

}
</script>
