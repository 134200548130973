//import EventBus from '@/eventBus';

export const SliderHelpersVue = {

  methods: {
    initSlider(sliderClass, params) {
      let $slider           = $(sliderClass);
      let $sliderNextBtn    = $slider.find('.swiper-button-next');
      let $sliderPrevBtn    = $slider.find('.swiper-button-prev');
      let $sliderPagination = $slider.find('.swiper-pagination');

      let sliderParams = {};

      let that = this;

      let sliderParamsDefault = {
        init: false,
        //autoplay: {
        //  delay: 5000
        //},
        slidesPerView: 3,
        //spaceBetween: 10,
        navigation: {
          nextEl: $sliderNextBtn,
          prevEl: $sliderPrevBtn
        },
        pagination: {
          el: $sliderPagination,
          type: 'bullets',
          clickable: true
        },
        loop: true, // в цикле не работают события вью из-за клонирования слайдов без событий
        simulateTouch: true,
        observer: true, // если слайдер изначально скрыт
        observeParents: true, // если слайдер изначально скрыт
        on: {
          init() {
            that.classesOnHover(sliderClass);
          }
        }
      };

      if(typeof sliderParams !== 'undefined') {
        sliderParams = Object.assign(sliderParamsDefault, params);
      }

      if($slider.find('.swiper-slide').length <= sliderParams.slidesPerView) {
        sliderParams = Object.assign(sliderParams, {
          loop: false,
          virtualTranslate: true
        });

        $sliderNextBtn.hide();
        $sliderPrevBtn.hide();
        $sliderPagination.hide();
      }

      if($slider.length) {
        let swiper = new Swiper($slider.find('.swiper-container'), sliderParams);

        swiper.init();
      }
    },

    classesOnHover(sliderClass) {
      let $slider = $(sliderClass);
      let $slides = $slider.find('.slider-similar__item');

      $slides.hover(function() {
          $slider.find('.slider-similar__item').not($(this)).addClass('slider-similar__item--layout');
        },
        function() {
          $slider.find('.slider-similar__item--layout').removeClass('slider-similar__item--layout');
        });
    }
  }
};
