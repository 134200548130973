import * as types from './mutation-types';

/*export const mutations = {
  [types.setFooter] (state, payload) {
    state.footer.push(...payload)
  }
};*/

export const mutations = {
  [types.setFooter](state, payload) {
    state.footer = {
      top: payload.top || null,
      bottom: payload.bottom
    }
  }
};