import normalize from 'normalize.css';

import 'swiper/dist/css/swiper.min.css';
import '@/assets/stylus/common.styl';

window.$ = window.jQuery = require('jquery');
window.Swiper = window.Swiper = require('swiper/dist/js/swiper.min.js');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import store from './store';
import VueMask from 'v-mask';
import VueParticles from 'vue-particles';

Vue.use(VueParticles);
Vue.use(VueMask);

//Vue.use(VueResource);

Vue.config.productionTip = false;

/*router.beforeEach((to, from, next) => {
  console.log(to);

  let event = '';
  let path   = to.path || '';

  //let path  = to.path;

  //console.log(route.params.type);

  if(path === '/') {
    event = 'loadMainPage';
  }
  else if(path === '/produkciya') {
    event = 'loadProductionPage';
  }
  else if(path === '/katalog') {
    event = 'loadCatalogPage';
  }
  else {
    event = 'load404Page';
  }

  console.log(event);

  EventBus.$emit(event, path);

  next();
});*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
