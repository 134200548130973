<template lang="pug">
  .samedia
    .samedia__created
      a.samedia__created-lnk(href='http://samedia.ru', target='_blank') Создано в Samedia
    a.samedia__logo-wr(href='http://samedia.ru', target='_blank')
      img.samedia__logo-black(src='/img/content/samedia-logo-black.svg', alt='Samedia')
      img.samedia__logo(src='/img/content/samedia-logo.png', alt='Samedia')
</template>

<script>
  import '@/components/common/samedia/samedia.styl';

  export default {
    name: 'Samedia',
  }
</script>
