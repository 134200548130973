<template lang="pug">
  .cool-hover
    .cool-hover__item-hover-el(v-for="el in [0, 1, 2, 3]")
      .cool-hover__item-hover-el-1
      .cool-hover__item-hover-el-2
</template>

<script>
  import '@/components/common/coolHover/coolHover.styl';

  export default {
    name: 'coolHover',

    data() {
      return {

      }
    },

    components: {

    }

  }
</script>
