<template lang="pug">
  .catalog-single(v-if="productDesc")
    .page__wr
      .catalog-single__content
        h1.catalog-single__title
          | {{h1}}
        .catalog-single__content-desc
          .catalog-single__content-desc-left
            .catalog-single__content-desc-img-left
              img.catalog-single__content-desc-img(:src="productDesc.img", :alt="h1")
            .catalog-single__content-desc-img-right
              .catalog-single__content-info-wr
                .catalog-single__content-info-code-wr
                  .catalog-single__content-info-code-title
                    | {{productDesc.info.infoCodeTitle}}
                  .catalog-single__content-info-code
                    | {{productDesc.info.infoCode}}
                .catalog-single__content-info-instock
                  | {{productDesc.inStock}}
                .catalog-single__content-info-country-wr
                  .catalog-single__content-info-country-title
                    | {{productDesc.country.countryTitle}}
                  .catalog-single__content-info-country
                    | {{productDesc.country.countryName}}

          .catalog-single__content-desc-right
            .catalog-single__content-desc-title
              | {{productDesc.desc.descTitle}}
            .catalog-single__content-desc-txt(v-html="productDesc.desc.descTxt")
            button.catalog-single__content-order-btn(type="button", v-on:click="popupLocal('popupPhone', 'show')")
              | {{productDesc.orderBtnTitle}}

        .catalog-single__table-wr(v-if="productTable")
          .catalog-single__table-title
            | {{productTable.tableTitle}}
          .catalog-single__table(v-html="productTable.table")

          //table.catalog-single__table
            tbody
              tr(v-for="tr in productTable.table.tr", :key="tr.id")
                td(v-for="td in tr.td", :key="td.id")
                  .catalog-single__table-td-title
                    | {{td.tdTitle}}
                  .catalog-single__table-td-content
                    | {{td.tdContent}}

        .catalog-single__slider-similar
          sliderSimilarProducts(:productSimilar="productSimilar")

    .catalog-single__footer
      Footer
</template>

<script>

import EventBus from '@/eventBus';

import '@/components/catalogSingle/catalogSingle.styl';

import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';

// @ is an alias to /src
import sliderSimilarProducts from '@/components/common/sliderSimilarProducts/sliderSimilarProducts.vue';
import Footer from '@/components/common/footer/Footer.vue';

export default {
  name: 'Main',

  mixins: [AjaxHelpersVue, PopupHelpersVue],

  data() {
    return {
      productDesc: null,
      productTable: null,
      productSimilar: null
    }
  },

  components: {
    sliderSimilarProducts,
    Footer
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadCatalogSinglePage', params => {
      this.request(params.url);
    });
  },

  methods: {
    request(url) {
      this.req('CatalogSinglePage:getCatalogSinglePage', {url}, res => {
        if(this.checkProperty(res.content.productDesc)) {
          this.productDesc = res.content.productDesc;
        }

        if(this.checkProperty(res.content.productTable)) {
          this.productTable = res.content.productTable;
        }

        if(this.checkProperty(res.content.productSimilar)) {
          this.productSimilar = res.content.productSimilar;
        }
      }, 'catalogSingle');
    },

    popupLocal(type, action) {
      this.popup(type, action);
    }
  }

}
</script>
