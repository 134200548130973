<template lang="pug">
  .popup-call.js-popup-call(v-if="content")
    .popup-call__layout(v-on:click="popupLocal('popupPhone', 'close')")
    .popup-call__content-wr
      .popup-call__content-layout
        .popup-call__lines
          Particles( color="#fedb8b", :particleOpacity="1", :particlesNumber="80" shapeType="circle", :particleSize="3" linesColor="#fedb8b", :linesWidth="1", :lineLinked="true", :lineOpacity="1", :linesDistance="150", :moveSpeed="1", :hoverEffect="true" hoverMode="grab", :clickEffect="true" clickMode="push", id="js-particles-popup-call")

      .popup-call__close-wr(v-on:click="popupLocal('popupPhone', 'close')")
        SvgIcon(class='popup-call__close-ico' name="close" width="42" height="42")

      .popup-call__content
        .popup-call__title-wr
          .popup-call__title
            | {{content.title}}
          .popup-call__close-wr.popup-call__close-wr--mobile(v-on:click="popupLocal('popupPhone', 'close')")
            SvgIcon(class='popup-call__close-ico' name="close" width="42" height="42")
        form.popup-call__form(v-on:submit.prevent="submit")
          .popup-call__input-wr
            input.popup-call__input(v-model="name" type="text" name="name", :placeholder="content.inputName.placeholder" autocomplete="off" required)
          .popup-call__input-wr
            input.popup-call__input(v-model="phone" v-mask="content.inputPhone.mask" type="phone" name="phone", :placeholder="content.inputPhone.placeholder" autocomplete="off" required)
            .popup-call__input-txt
              | {{content.requiredTxt}}
          textarea.popup-call__textarea(v-model="comment" name="comment", :placeholder="content.textAreaComment.placeholder")
          .popup-call__btn-wr
            button.popup-call__btn(type="submit")
              | {{content.btnSubmit.title}}
            .popup-call__agreement
              p.popup-call__agreement-text(v-html="content.agreement")

</template>

<script>
  import EventBus from '@/eventBus';

  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import Particles from '@/components/common/vue-particles/vue-particles.vue';

  // @ is an alias to /src
  import '@/components/common/popups/popupCallPhone/popupCallPhone.styl';

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
  import {FormHelpersVue} from '@/mixins/FormHelpersVue';

  export default {
    name: 'PopupCall',

    mixins: [AjaxHelpersVue, PopupHelpersVue, FormHelpersVue],

    components: {
      Particles,
      SvgIcon
    },

    data() {
      return {
        content: null,
        name: '',
        phone: '',
        comment: ''
      }
    },

    created() {
      EventBus.$on('loadFormCall', url => {
        this.request(url);
      });

      this.$nextTick(() => {
        //this.initParallax();
      })
    },

    methods: {
      submit() {
        let data = {
          name: this.name,
          phone: this.phone,
          comment: this.comment
        };

        this.submitForm('FormPhone:SendFormPhone', data, () => {
          this.name  = '';
          this.phone = '';
          this.comment = '';

          this.popupLocal('popupPhone', 'close')
        });
      },

      request(url) {
        this.req('FormPhone:getFormPhone', {url}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;

            this.$nextTick(() => {
              this.initParallax();

              this.popupLocal('popupPhone', 'show');

              //this.initParallax($('.popup-order__lines'));
            });
          }
        }, null, 'form');
      },

      initParallax() {
        let $bgLines  = $('.popup-call__lines');

        $(document).on('mousemove', function(e) {
          let offsetX = e.clientX;
          let offsetY = e.clientY;

          $bgLines.css('transform', `translate3d(${offsetX * 0.0075}px, ${offsetY * 0.0075}px, 0) `);
        });
      },

      popupLocal(type, action) {
        this.popup(type, action);
      }
    }
  }
</script>
