<template lang="pug">
  .main(v-if="content")
    .main__layout
      .main__layout-lines-wr
        .main__layout-lines
          Particles(color="#C8C8C8", :particleOpacity="0.7", :particlesNumber="80" shapeType="circle", :particleSize="4" linesColor="#C8C8C8", :linesWidth="1", :lineLinked="true", :lineOpacity="0.4", :linesDistance="150", :moveSpeed="3", :hoverEffect="true" hoverMode="grab", :clickEffect="true" clickMode="push", id="js-particles-main")
    .page__wr
      .main__content
        .main__content-left
          h1.main__title(v-html="h1")
          .main__subtitle
            | {{content.subtitle}}
            .main__content-left
        .main__content-right
          .logo-anima.logo-anima--paused.logo-anima--step-1(v-if="logoAnimaItems")
            .logo-anima__bg-wr
              .logo-anima__bg
              router-link.logo-anima__item-lnk(:to="logoAnimaItems.center.link")
                | {{logoAnimaItems.center.title}}
            .logo-anima__items
              .logo-anima__item.logo-anima__item--left
                img.logo-anima__item-img(src="/img/content/logo-left.svg" alt="Avantage")
                router-link.logo-anima__item-lnk(:to="logoAnimaItems.left.link" alt="Avantage")
                  | {{logoAnimaItems.left.title}}
              .logo-anima__item.logo-anima__item--center
                img.logo-anima__item-img(src="/img/content/logo-center.svg")
                router-link.logo-anima__item-lnk(:to="logoAnimaItems.top.link" alt="Avantage")
                  | {{logoAnimaItems.top.title}}
              .logo-anima__item.logo-anima__item--right
                img.logo-anima__item-img(src="/img/content/logo-right.svg")
                router-link.logo-anima__item-lnk(:to="logoAnimaItems.right.link" alt="Avantage")
                  | {{logoAnimaItems.right.title}}

    .main__footer
      .page__wr
        .main__footer-content
          .main__footer-left
            | {{$store.state.footer.bottom.copyright}}
          .main__footer-right
            Samedia

</template>

<script>
import EventBus from '@/eventBus';

import '@/components/main/main.styl';
import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

// @ is an alias to /src
import Samedia from '@/components/common/samedia/Samedia.vue';
import Particles from '@/components/common/vue-particles/vue-particles.vue';

export default {
  name: 'Main',

  mixins: [AjaxHelpersVue],

  data() {
    return {
      content: null,
      footer: null,
      logoAnimaItems: null,
      showParticles: false,
      logoAnimaIsInit: false,
      clicked: false,
      step1End: false,
      step2End: false
    }
  },

  components: {
    Particles,
    Samedia
  },

  created() {
    // Загрузка данных для страницы
    EventBus.$on('loadMainPage', params => {
      this.request(params.url);
    });

    EventBus.$on('changePage', blockId => {
      this.showParticles = blockId !== 'production' ? true : false;
    });
  },

  methods: {
    request(url) {
      this.req('MainPage:getMainPage', {url}, res => {
        if(this.checkProperty(res.content)) {
          this.content = res.content;

          this.$nextTick(() => {
            if($(window).width() >= 1260) {
              this.initLogoAnima();
            }
          });
        }

        if(this.checkProperty(res.footer)) {
          this.footer = res.footer;
        }

        if(this.checkProperty(res.content.logoAnimaItems)) {
          this.logoAnimaItems = res.content.logoAnimaItems;
        }
      }, 'main');
    },

    // Оставь надежды всяк сюда входящий :)
    initLogoAnima() {
      let $logo = $('.logo-anima');

      let that = this;

      setTimeout(() => {
        // Запуск анимации
        $logo.removeClass('logo-anima--paused');
      }, 500);

      if(!this.logoAnimaIsInit) {
        this.logoAnimaIsInit = true;

        // Общий блок для трех элементов логотипа
        $logo.find('.logo-anima__items').on('animationend', function(e) {
          let animaName = e.originalEvent.animationName;

          if(animaName === 'logoStep1') {
            that.step1End = true;

            if(!that.clicked) {
              $logo.addClass('logo-anima--paused');

              // Поворот на 360 градусов
              $logo.addClass('logo-anima--step-2');

              setTimeout(() => {
                // Задежка перед вторым шагом анимации
                $logo.removeClass('logo-anima--paused');
              }, 500);
            }
          }

          if(animaName === 'logoStep2') {
            that.step2End = true;

            if(!that.clicked) {
              // Движение элементов логотипа в разные стороны
              $logo.addClass('logo-anima--step-3');
            }
          }

          // Клик по общему блоку, чтобы сразу произошло раскрытие элементов
          if(that.clicked) {
            if(that.step1End && !that.step2End) {
              $logo.addClass('logo-anima--step-3');
            }

            if(that.step1End && that.step2End) {
              $logo.addClass('logo-anima--step-3');
            }
          }
        });

        //
        $('.logo-anima__item--left').on('transitionend', e => {

          if($(e.originalEvent.target).hasClass('logo-anima__item--left')) {
            // Появление пунктов меню
            $logo.addClass('logo-anima--step-4');

            this.clicked = false;
            this.step1End = false;
            this.step2End = false;
          }
        });

        // Событие навешивается на пункт меню, который посередке
        $('.logo-anima__bg-wr').find('.logo-anima__item-lnk').on('transitionend', () => {
          // Анимация закончена, всем спасибо)
          $logo.addClass('logo-anima--step-final');

          //$('body').addClass('bezumie');
        });

        // Клик по общему блоку
        $logo.on('click', function() {
          that.clicked = true;

          $logo.removeClass('logo-anima--paused');
        });
      }
    }

    /*popupLocal(type, action) {
      this.popup(type, action);
    }*/
  }

}
</script>
