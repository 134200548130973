<template lang="pug">
  .menuMobile(:class="{'menuMobile--show': showHideMenu}")
    .menuMobile__content
      .menuMobile__langs(v-if="langs")
        a.menuMobile__lang(:href="lang.link", v-for="lang in langs", :key="lang.title")
          | {{lang.title}}
      .menuMobile__menu-items(v-if="menuItems")
        router-link.menuMobile__menu-item(v-for='item in menuItems', :to="item.link", :key="item.id", v-on:click.native="closeMenu")
          | {{item.title}}
</template>

<script>
  import '@/components/common/menuMobile/menuMobile.styl';

  import EventBus from '@/eventBus';

  export default {
    name: 'MenuMobile',

    data() {
      return {
        langs: null,
        menuItems: null,
        showHideMenu: false,
        path: ''
      }
    },

    components: {

    },

    created() {

      // Данные страницы загружены
     EventBus.$on('pageLoaded', res => {
        this.langs  = res.header.langs;
        this.menuItems = res.menuTop;
      });

      EventBus.$on('setMenuTopActiveClass', path => {
        this.path = path;
      });

      EventBus.$on('showHideMenu', showHide => {
        this.showHideMenu = showHide;

        if(showHide) {
          $('.page').addClass('overflow');
        }
        else {
          $('.page').removeClass('overflow');
        }
      });
    },

    methods: {

      closeMenu() {
        this.showHideMenu = false;

        $('.page').removeClass('overflow');

        EventBus.$emit('showHideMenu', false);
      }
    }
  }
</script>
