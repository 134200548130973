<template lang="pug">
  .popup-message.js-popup-message
    .popup-message__layout(v-on:click="popupLocal('popupMessage', 'close')")
    .popup-message__content-wr
      .popup-message__content-layout
        .popup-message__lines
          Particles( color="#fedb8b", :particleOpacity="1", :particlesNumber="80" shapeType="circle", :particleSize="3" linesColor="#fedb8b", :linesWidth="1", :lineLinked="true", :lineOpacity="1", :linesDistance="150", :moveSpeed="1", :hoverEffect="true" hoverMode="grab", :clickEffect="true" clickMode="push", id="js-particles-popup-message")
      .popup-message__close-wr(v-on:click="popupLocal('popupMessage', 'close')")
        SvgIcon(class='popup-message__close-ico' name="close" width="42" height="42")

      .popup-message__content
        .popup-message__message(v-if="message")
          | {{message}}

</template>

<script>
  import EventBus from '@/eventBus';

  import Particles from '@/components/common/vue-particles/vue-particles.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  // @ is an alias to /src
  import '@/components/common/popups/popupMessage/popupMessage.styl';

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
  import {AnimaHelpersVue} from '@/mixins/AnimaHelpersVue';

  export default {
    name: 'PopupCall',

    mixins: [AjaxHelpersVue, PopupHelpersVue, AnimaHelpersVue],

    components: {
      Particles,
      SvgIcon
    },

    data() {
      return {
        message: null
      }
    },

    created() {
      EventBus.$on('popupMessageDataLoaded', res => {
        if(this.checkProperty(res.message)) {
          this.message = res.message;
        }
      });

      this.$nextTick(() => {
        this.initParallax($('.popup-message__lines'));
      });
    },

    methods: {
      popupLocal(type, action) {
        this.popup(type, action);
      }
    }
  }
</script>
