<template lang="pug">
  .contacts(v-if="mapContent")
    .contacts__content
      .page__wr
        h1.contacts__title
          | {{h1}}
      .contacts__map-wr
        .contacts__map.js-contacts-map(v-if="initMapConfig")
          .contacts__map-preloader
            .contacts__amazing-spinner
              AmazingSpinner
          .contacts__map-content-close-btn.js-contacts-map-close-btn
            SvgIcon(class='contacts__map-content-close-btn-ico' name="close" width="30" height="30")

        .contacts__map-content-wr
          .page__wr
            .contacts__map-content
              .contacts__map-content-items
                .contacts__map-address(v-if="mapContent.address")
                  | {{mapContent.address}}
                .contacts__map-phone-wr(v-if="mapContent.phone")
                  .contacts__map-phone-title
                    | {{mapContent.phone.title}}
                  a.contacts__map-phone(:href="mapContent.phone.phoneHref")
                    | {{mapContent.phone.phoneLnkTxt}}
                .contacts__map-email-wr(v-if="mapContent.email")
                  .contacts__map-email-title
                    | E-mail:
                  a.contacts__map-email(:href="mapContent.email.emailHref")
                    | {{mapContent.email.emailLnkTxt}}
              .contacts__see-btn.js-see-btn
                .contacts__see-btn-circle-2
                  SvgIcon(class='contacts__see-btn-circle-2-ico' name="plus" width="60" height="60")

    .page__wr
      .contacts__list
        .contacts__list-main
          .contacts__list-main-item(v-for="(item, index) in contactsList.main" :key="index")
            h3.contacts__list-main-item-title(v-html="item.title")
            a.contacts__list-main-item-text(:href="item.phone")  Телефон: {{item.phone}}
            p.contacts__list-main-item-text Email: {{item.email}}
        .contacts__list-subordinate
          .contacts__list-subordinate-item(v-for="(item, index) in contactsList.subordinate" :key="index")
            h3.contacts__list-subordinate-item-title(v-html="item.title")
            a.contacts__list-subordinate-item-text(:href="item.phone")  Телефон: {{item.phone}}
            p.contacts__list-subordinate-item-text Email: {{item.email}}
    .contacts__form-feedback
      FormFeedback(:dataForm="dataForm")
    .contacts__footer
      Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/contacts/contacts.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {YaHelpersVue} from '@/mixins/YaHelpersVue';

  // @ is an alias to /src
  import FormFeedback from '@/components/common/formFeedback/formFeedback.vue';
  import Footer from '@/components/common/footer/Footer.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import AmazingSpinner from '@/components/common/amazingSpinner/amazingSpinner.vue';

  export default {
    name: 'Contacts',

    mixins: [AjaxHelpersVue, YaHelpersVue],

    data() {
      return {
        isMapInit: false,
        mapContent: null,
        initMapConfig: null,
        contactsList: null,
        dataForm: null
      }
    },

    components: {
      SvgIcon,
      FormFeedback,
      Footer,
      AmazingSpinner
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadContactsPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('ContactsPage:getContactsPage', {url}, res => {
          if(this.checkProperty(res.content.form)) {
            this.dataForm = res.content.form;
          }

          if(this.checkProperty(res.content.mapContent)) {
            this.mapContent = res.content.mapContent;
            this.contactsList = res.content.contactsList;
          }

          if(this.checkProperty(res.content.initMapConfig)) {
            this.initMapConfig = res.content.initMapConfig;

            this.$nextTick(() => {
              if(!this.isMapInit) {
                this.initMapLocal('.js-contacts-map', this.initMapConfig);
              }
            })
          }
        }, 'contacts');
      },

      initMapLocal(block, initMapConfig) {
        this
          .initMap(block, initMapConfig)
          // Когда карта загружена
          .then(() => {
            // Добавляем на карту placemarks
            this.addAllPlacemarks(initMapConfig.coords).then(() => {

              setTimeout(() => {
                $('.contacts__map-preloader').addClass('contacts__map-preloader--hide');

                $('.js-see-btn').addClass('contacts__see-btn--show');
              }, 750);
            });

            this.isMapInit = true;
          });

        $('.js-see-btn').on('click', function() {
          $('.contacts__map-content-wr').addClass('contacts__map-content-wr--hide');
          $('.contacts__map-content-close-btn').addClass('contacts__map-content-close-btn--show');
        });

        $('.js-contacts-map-close-btn').on('click', function() {
          $(this).removeClass('contacts__map-content-close-btn--show');
          $('.contacts__map-content-wr').removeClass('contacts__map-content-wr--hide');
        });
      }
    }
  }
</script>
