import EventBus from '@/eventBus';

export const AjaxHelpersVue = {
  data() {
    return {
      //pageLoaded: false

      h1: ''
    }
  },

  methods: {
    req(api, data, callback, blockId, queryType='common') {
      data = data || {};

      if(blockId !== 'main') {
        EventBus.$emit('preloaderShow');
      }

      //console.log(api);
      //console.log(data);
      //console.log(blockId);

      PrologueClient.queryToServer(api, data, res => {

        if(!this.check404(res) && blockId !== 'page-404') {
          EventBus.$emit('load404Page');

          return false;
        }

        if(queryType === 'common') {
          this.queryCommon(callback, blockId, res);
        }
        if(queryType === 'popup') {
          this.queryPopup(res);
        }
        if(queryType === 'form') {
          this.queryForm(callback, res);
        }

        //EventBus.$emit('preloaderHide');
      });
    },

    check404(res) {
      if(this.checkProperty(res.status) && res.status == '404') {
        return false;
      }

      return true;
    },

    queryCommon(callback, blockId, res) {
      // Данные для хедера и главного меню
      EventBus.$emit('pageLoaded', {
        header: res.header,
        menuTop: res.menuTop
      });

      // Данные для футера
      this.$store.commit('setFooter', res.footer);
      //this.$store.dispatch('setFooter', res.footer);

      // Установить meta для страницы
      if(this.checkProperty(res.meta)) {
        this.setMeta(res.meta);
      }

      // Первая загрузка
      this.setPageLoaded();

      this.setPageBlockVisible(blockId);

      if($.isFunction(callback)) {
        callback(res);
      }
    },

    queryPopup(res) {
      EventBus.$emit('popupDataLoaded', res);
    },

    queryForm(callback, res) {
      if($.isFunction(callback)) {
        callback(res);
      }

      EventBus.$emit('preloaderHide');
    },

    // Выбор активного блока для страницы
    setPageBlockVisible(blockId) {
      // Перемещение страницы к самому верху
      $('html, body').animate({
        scrollTop: 0
      }, 0);

      // Может понадобиться
      //EventBus.$emit('changePage', blockId);

      $('.page__item--active').removeClass('page__item--active');
      $('.page__item--anima').removeClass('page__item--anima');

      let $activeBlock = $(`.page__item[data-id=${blockId}]`);

      $activeBlock.addClass('page__item--active');

      $('.page').attr('data-block', blockId);

      this.$nextTick(() => {
        let $header = $('.header');

        // Header classes
        if(blockId !== 'main') {
          $header.addClass('header--black');
        }
        else {
          $header.removeClass('header--black');
        }

        // Классы анимации лого на главной
        if(blockId !== 'main') {
          //$('.logo-anima').attr('class', 'logo-anima logo-anima--step-3 logo-anima--step-4 logo-anima--step-final'); // открытый блок
          $('.logo-anima').attr('class', 'logo-anima logo-anima--paused logo-anima--step-1'); // закрытый блок
        }

        setTimeout(() => {
          $activeBlock.addClass('page__item--anima');

          EventBus.$emit('preloaderHide');
        }, 20); // задержки нужны, чтобы анимация работала плавнее

        // Анимация для продукции
        /*setTimeout(() => {
          let $production = $('.production');

          if(blockId === 'main') {
            $production.removeClass('production--anima');
          }

          if(blockId === 'production') {
            $production.addClass('production--anima');
          }
        }, 150);*/ // задержки нужны, чтобы анимация работала плавнее
      });

      //console.log(blockId);
    },

    setMeta(res) {
      if(res.title.length) {
        $('title').text(res.title);
      }

      if(res.description.length) {
        $('[name="description"]').attr('content', res.description);
      }

      if(res.h1.length) {
        this.h1 = res.h1;
      }
    },

    // Для первой загрузки
    setPageLoaded() {
      let $page = $('.page');

      if(!$page.hasClass('page--loaded')) {
        $page.addClass('page--loaded');

        //this.pageLoaded = true;
      }
    },

    checkProperty(prop) {
      return typeof(prop) !== 'undefined' && $.type(prop) !== 'null';
    },

    /**
     * Проверка загрузки изображений
     * @param objImages {object}
     * @returns {Promise}
     * @description Формат принимаего объекта, где loaded - статус изображения по-умолчанию не загружен; src - ссылка на изображение
     var objImages = [
     {
       loaded: false,
       src: img1Src
     },
     {
       loaded: false,
       src: img2Src
     }
     ];
     */
    checkImgLoading(objImages) {
      return new Promise((resolve, reject) => {
        let loaded = false;

        $.each(objImages, (i, el) => {
          $('<img />').attr('src', el.src).on('load', function() {
            $(this).remove();

            objImages[i].loaded = true;
          });
        });

        let int = setInterval(() => {
          $.each(objImages, (i, el) => {
            loaded = el.loaded ? true : false;
          });

          if(loaded) {
            clearInterval(int);
            EventBus.$emit('imgLoaded', false);
            // Все изображения загрузились, резолвим
            resolve();
          }
        }, 250);
      });
    }
  }
};
