<template lang="pug">
  header.header(v-if="header")
    .header__left
      router-link.header__logo-lnk(to="/")
        img.header__logo-img(src="/img/content/logo.svg" alt="Avantage")
        .header__logo-title
          | Avantage

    .header__center
      .header__center-menu
        MenuTop(:items="menuTopItems", :path="path")
      .header__center-contacts
        a.header__center-contact.header__center-contact--phone(:href="`tel:${header.phone.link}`", v-html="header.phone.title")
        a.header__center-contact(:href="`mailto:${header.email}`")
          | {{header.email}}

    .header__right
      .header__right-btns
        button.header__right-btn(type="button", v-on:click="showPopupCall()")
          | {{header.bntCall.title}}
        button.header__right-btn(type="button", v-on:click="showPopupOrder()")
          | {{header.btnPartOfCompanyLnk.title}}
      // .header__right-lang--active
      .header__right-langs-wr
        .header__right-langs(v-if="header.langs", v-for="lang in header.langs", :key="lang.title")
          a.header__right-lang(:href="lang.link")
            | {{lang.title}}
</template>

<script>
  import '@/components/common/header/header.styl';

  import EventBus from '@/eventBus';

  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';

  import MenuTop from '@/components/common/menuTop/menuTop.vue';

  export default {
    name: 'Header',

    mixins: [PopupHelpersVue],

    data() {
      return {
        header: null,
        menuTopItems: [],
        path: ''
      }
    },

    components: {
      MenuTop
    },

    created() {
      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        this.header  = res.header;
        this.menuTopItems = res.menuTop;
      });

      EventBus.$on('setMenuTopActiveClass', path => {
        this.path = path;
      });
    },

    methods: {
      //popupLocal(type, action) {
      //  this.popup(type, action);
      //},

      showPopupOrder() {
        EventBus.$emit('loadFormOrder', '/formVacancy');
      },

      showPopupCall() {
        EventBus.$emit('loadFormCall', '/formPhone');
      }
    }
  }
</script>
