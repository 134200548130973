<template lang="pug">
  .preloader
    .preloader__line
</template>

<script>
  import '@/components/common/preloader/preloader.styl';

  import EventBus from '@/eventBus';

  export default {
    name: 'Preloader',

    components: {

    },

    data() {
      return {

      }
    },

    created() {
      EventBus.$on('preloaderShow', () => {
        this.$nextTick(() => {
          $('.preloader').addClass('preloader--start').removeClass('preloader--hide');
        });
      });

      EventBus.$on('preloaderHide', () => {
        this.$nextTick(() => {
          $('.preloader').addClass('preloader--end');

          setTimeout(() => {
            $('.preloader').removeClass('preloader--start preloader--end').addClass('preloader--hide');
          }, 500);
        });
      });
    }
  }
</script>
