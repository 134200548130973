<template lang="pug">
  nav.menu-top(v-if="items")
    .menu-top__items
      router-link.menu-top__item(v-for='item in items', :to="item.link", :key="item.id")
        .menu-top__item-circle
        | {{item.title}}
</template>

<script>
  import '@/components/common/menuTop/menuTop.styl';

  export default {
    name: 'MenuTop',

    props: {
      items: {
        type: Array,
        required: true,
        default: null
      },

      path: {
        type: String
      }
    },

    watch: {
      path(newVal, oldVal) {
        this.path = newVal;

        this.$nextTick(() => {
          this.setActiveClass();
        })
      }
    },

    methods: {
      setActiveClass() {
        let $menuItems = $('.menu-top__items');
        let words      = [];

        if(this.path.length) {
          $menuItems.find('.menu-top__item--active').removeClass('menu-top__item--active');
          if(this.path !== '/') {
            words = this.path.substring(1).split('/');

            $menuItems.find(`[href*='${words[0]}']`).addClass('menu-top__item--active');
          }
          // Для главной /
          else {
            $menuItems.find(`[href='${this.path}']`).addClass('menu-top__item--active');
          }
        }
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.setActiveClass();

        /*$('.menu-top__item').on('click', function() {
          console.log('here');

          $('.menu-top__item--active').removeClass('menu-top__item--active');
          $(this).addClass('menu-top__item--active');
        });*/
      })
    }
  }
</script>
