<template lang="pug">
  footer.footer(v-if="$store.state.footer")
    .page__wr
      .footer__content
        .footer__top(v-if="$store.state.footer.top")
          .footer__item( v-for="(item, i) in $store.state.footer.top.item", :key="i")
            .footer__item-lnks
              router-link.footer__item-lnk(v-for="link in item.links", :to="link.link", :key="link.id")
                | {{link.title}}

          .footer__item
            .footer__contacts
              a.footer__contact.footer__contact--phone(:href="`tel:${$store.state.footer.top.contacts.phone.link}`")
                | {{$store.state.footer.top.contacts.phone.title}}
              a.footer__contact.footer__contact--email(:href="`mailto:${$store.state.footer.top.contacts.email.link}`")
                | {{$store.state.footer.top.contacts.email.title}}
              .footer__contact.footer__contact--samedia
                Samedia
        .footer__bottom
          .footer__copyright
            | {{$store.state.footer.bottom.copyright}}
</template>

<script>
  import '@/components/common/footer/footer.styl';

  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import Samedia from '@/components/common/samedia/Samedia.vue';

  export default {
    name: 'Footer',

    data() {
      return {
        footer: null
      }
    },

    components: {
      SvgIcon,
      Samedia
    }

  }
</script>
